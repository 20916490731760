import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="flash"
export default class extends Controller {
  static targets = ["inputState", "inputCategory", "inputDismissedAt", "partnerInput", "partnerItem", "partnerDescriptionText", "partnerDescription"]

  connect() {
    this.inputStateTarget.addEventListener("change", this.onStateChange)
    this.partnerInputTarget.addEventListener("autocomplete:completed", this.onPartnerChange)
    this.showHideDismissedAt()
  }

  onStateChange = (event) => {
    this.showHideDismissedAt()
  }

  showHideDismissedAt = () => {
    if(['financial', 'health'].includes(this.inputCategoryTarget.value)) {
      if(this.inputStateTarget.value === 'dismissed') {
        this.inputDismissedAtTarget.parentNode.classList.remove('hidden')
        this.inputDismissedAtTarget.value = new Date().toISOString().slice(0, 10)
      }
      else {
        this.inputDismissedAtTarget.parentNode.classList.add('hidden')
        this.inputDismissedAtTarget.value = ''
      }
    }
  }

  onPartnerChange = (event) => {
    const partner = this.partnerItemTargets.find(item => item.dataset.partner === event.detail.value)
    if(partner && partner.dataset.description) {
      this.partnerDescriptionTarget.classList.remove('hidden')
      this.partnerDescriptionTextTarget.innerHTML = partner.dataset.description
    } else {
      this.partnerDescriptionTarget.classList.add('hidden')
    }
  }
}
